<template>
  <div class="admin">
    <div class="overlay" v-show="overlay" @click="closeOverlay"></div>
    <section>
      <header>
        <nav class="rad-navigation">
          <mdi-semantic-web size="40"></mdi-semantic-web>
          Email system admin panel
        </nav>
      </header>
    </section>
    <main>
      <section>
        <div class="container-fluid">
          <div class="row counter">
            <div class="item1 col-lg-3 col-xs-6">
              <div class="rad-info-box">
                <mdi-web size="100"></mdi-web>
                <span class="heading">sites</span>
                <span class="value"><span>{{ statistics.siteCounter }}</span></span>
              </div>
            </div>
            <div class="item2 col-lg-3 col-xs-6">
              <div class="rad-info-box">
                <mdiClipboardCheckOutline size="100"></mdiClipboardCheckOutline>
                <span class="heading">sites completed</span>
                <span class="value"><span>{{ statistics.doneCounter }}</span></span>
              </div>
            </div>
            <div class="item3 col-lg-3 col-xs-6">
              <div class="rad-info-box">
                <mdi-clipboard-text-play-outline size="100"></mdi-clipboard-text-play-outline>
                <span class="heading">remain sites</span>
                <span class="value"><span>{{ statistics.todoCounter }}</span></span>
              </div>
            </div>
            <div class="item4 col-lg-3 col-xs-6">
              <div class="rad-info-box">
                <mdi-account size="100"></mdi-account>
                <span class="heading">users</span>
                <span class="value"><span>{{ statistics.usersCounter }}</span></span>
              </div>
            </div>

          </div>
          <div class="row users">
            <div class="col-12">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Users <span> {{ " " + hideUsers.length }} </span> <span @click="showAllUser">Show all user - </span>
                  </h3>
                </div>
                <div class="panel-body">
                  <section>
                    <table cellpadding="0" cellspacing="0" border="0">
                      <thead>
                      <tr>
                        <th>flags</th>
                        <th>number</th>
                        <th>username</th>
                        <th>Admin</th>
                        <th>Total Count</th>
                        <th>Today Count</th>
                        <th>Today Percent</th>
                        <th>no Contact Form</th>
                        <th>no contact form per user</th>
                        <th>Avg Time</th>
                        <th>Action</th>

                      </tr>
                      </thead>
                    </table>
                    <div class="tbl-content">
                      <table cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                        <tr v-for="(item,index) in users">
                          <td @click="openOverlay('userActivity',item)">
                            <mdi-flag class="warn" v-if="item.maxTime"></mdi-flag>
                            <mdi-flag class="error" v-if="item.minTime"></mdi-flag>
                            <mdi-flag class="success" v-if="!(item.minTime || item.maxTime)"></mdi-flag>
                          </td>
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.userName }}</td>
                          <td>
                            <span class="success" v-if="item.superUser === '1'">true</span>
                            <span v-else class="error">false</span>
                          </td>
                          <td> {{ item.totalActivityCounter === '1' ? 0 : item.totalActivityCounter }}</td>
                          <td>{{ item.oneDayActivity.sitesCounter }}</td>
                          <td> {{ Math.floor((item.oneDayActivity.sitesCounter / refreshCount)*100)  }}  %</td>
                          <td>{{ item.oneDayActivity.noContactFrom.counter }}</td>
                          <td>%{{ item.oneDayActivity.noContactFrom.percent }}</td>
                          <td>{{ item.oneDayActivity.averageOnPerSite }}</td>
                          <td>
                            <div @click="hideUser(index,item)" class="pointer">
                              <mdi-eye-off-outline></mdi-eye-off-outline>
                            </div>
                            <div @click="openOverlay('changePass',item)" class="pointer">
                              <mdi-key-plus></mdi-key-plus>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="row settings">
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Upload file</h3>
                </div>
                <div class="panel-body">
                  <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File"
                         accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <button @click="upload" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Upload
                    </template>
                  </button>
                  <div class="success" v-html="uploadRecord"></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">text</h3>
                </div>
                <div class="panel-body">
                  <textarea rows="6" v-model="text"></textarea>
                  <button @click="updateText" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Update
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">New user</h3>
                </div>
                <div class="panel-body">
                  <div class="inputBox">
                    <input type="text" v-model="userName" placeholder="username"> <span><mdi-account></mdi-account></span>
                  </div>
                  <div class="inputBox">
                    <input type="text" v-model="password" placeholder="password">
                    <span><mdi-shield-key-outline></mdi-shield-key-outline></span>
                  </div>
                  <button @click="addUser" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Add
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Rate Limits</h3>
                </div>
                <div class="panel-body">
                  <div class="inputBox">
                    <input type="text" v-model="minRate" placeholder="minimum time (seconds)">
                    <span><mdi-timer-outline></mdi-timer-outline></span>
                  </div>
                  <div class="inputBox">
                    <input type="text" v-model="maxRate" placeholder="maximum time (seconds)">
                    <span><mdi-timer-outline></mdi-timer-outline></span>
                  </div>
                  <button @click="updateRateLimit" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Change
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Refresh count</h3>
                </div>
                <div class="panel-body">
                  <div class="inputBox">
                    <input type="text" v-model="refreshCount" placeholder="Refresh count"> <span><mdi-refresh></mdi-refresh></span>
                  </div>
                  <button @click="updateRefreshCount" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Update
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Refresh Text</h3>
                </div>
                <div class="panel-body">
                  <textarea rows="6" v-model="refreshText"></textarea>
                  <button @click="updateRefreshText" class="upload-file">
                    <template v-if="loading">
                      <mdi-loading spin></mdi-loading>
                    </template>
                    <template v-else>
                      Update
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="panel">
                <div class="panel-heading">
                  <h3 class="panel-title">Export</h3>
                </div>
                <div class="panel-body">
                  <div style="margin-bottom: 10px">
                    <a :href="$store.state.baseUrl+'/export-database?token='+$store.state.user.token" class="upload-file">
                      <template v-if="loading">
                        <mdi-loading spin></mdi-loading>
                      </template>
                      <template v-else>
                        Export
                      </template>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>

    <div class="overlay-content" :class="{'activity-panel': overlayContent =='userActivity'}" v-show="overlay">

      <div class="panel" v-if="overlayContent === 'changePass'">
        <div class="panel-heading">
          <h3 class="panel-title">change password</h3>
        </div>
        <div class="panel-body">
          <div class="inputBox">
            <input type="text" v-model="newPassword" placeholder="new password">
            <span><mdi-shield-key-outline></mdi-shield-key-outline></span>
          </div>
          <button @click="changePass" class="upload-file">
            <template v-if="loading">
              <mdi-loading spin></mdi-loading>
            </template>
            <template v-else>
              Change pass
            </template>
          </button>
        </div>
      </div>

      <div class="panel" v-if="overlayContent === 'userActivity' && !loading">
        <div class="panel-heading">
          <h3 class="panel-title">userActivity <span>username: <b>{{ userSelected.userName }}</b></span></h3>
          Total site: <b> {{ oneDayUserActivity.oneDayActivity.sitesCounter }}</b>
          <span><input class="dayAgo" type="number" v-model="dayAgo" min="0" max="100"></span><span class="mr-2">day ago: </span><br>
          No contact form: <b> {{ oneDayUserActivity.oneDayActivity.noContactFrom.counter }}</b><br>
          Average time: <b> {{ oneDayUserActivity.oneDayActivity.averageOnPerSite }}</b>
          <span>
              <button @click="getUserActivity" class="upload-file mb-2 go">
              <template v-if="loading"><mdi-loading spin></mdi-loading></template><template v-else>Go
              </template>
              </button>
          </span>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-12 pt-2 mb-3 activity">
              <div class="row">
                <div class="col-3"><b>Site</b></div>
                <div class="col-2"><b>Has Form</b></div>
                <div class="col-3"><b>Time</b></div>
                <div class="col-2"><b>Duration (s)</b></div>
                <div class="col-2"><b>Event</b></div>
              </div>
            </div>
            <div class="col-12 mb-3" v-for="(item,index) in oneDayUserActivity.sitesList">
              <div class="row">
                <div class="col-3">{{ item.url }}</div>
                <div class="col-2">
                  <span class="success" v-if="parseInt(item.hasContactForm)">true</span>
                  <span class="error" v-else>false</span>
                </div>
                <div class="col-3">{{ item.time }}</div>
                <div class="col-2">
                  {{
                    index !== oneDayUserActivity.sitesList.length - 1 && (parseInt(oneDayUserActivity.sitesList[index].timestamp) - parseInt(oneDayUserActivity.sitesList[index + 1].timestamp))
                  }}
                </div>
                <div class="col-2">
                  <span class="error"
                        v-if="index!== (oneDayUserActivity.sitesList.length -1) && ((parseInt(oneDayUserActivity.sitesList[index].timestamp) - parseInt( oneDayUserActivity.sitesList[index +1].timestamp)) < minRate)">
                    min time
                  </span>
                  <span class="warn"
                        v-else-if="index!== (oneDayUserActivity.sitesList.length -1) && ((parseInt(oneDayUserActivity.sitesList[index].timestamp) - parseInt( oneDayUserActivity.sitesList[index +1].timestamp)) > maxRate)">
                    max time
                  </span>
                  <span class="success" v-else>
                    good
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import mdiSemanticWeb from "mdi-vue/SemanticWeb.vue";
import mdiWeb from "mdi-vue/Web.vue";
import mdiClipboardCheckOutline from "mdi-vue/ClipboardCheckOutline.vue";
import mdiClipboardTextPlayOutline from "mdi-vue/ClipboardTextPlayOutline.vue";
import mdiAccount from "mdi-vue/Account.vue";
import mdiLoading from "mdi-vue/Loading.vue";
import mdiShieldKeyOutline from "mdi-vue/ShieldKeyOutline.vue";
import mdiTimerOutline from "mdi-vue/TimerOutline.vue";
import mdiFlag from "mdi-vue/Flag.vue";
import mdiKeyPlus from "mdi-vue/KeyPlus.vue";
import mdiEyeOffOutline from "mdi-vue/EyeOffOutline.vue";
import mdiRefresh from "mdi-vue/Refresh.vue";

export default {

  name: "admin",

  components: {
    mdiSemanticWeb,
    mdiWeb,
    mdiClipboardCheckOutline,
    mdiClipboardTextPlayOutline,
    mdiAccount,
    mdiLoading,
    mdiShieldKeyOutline,
    mdiTimerOutline,
    mdiFlag,
    mdiEyeOffOutline,
    mdiKeyPlus,
    mdiRefresh,
  },

  data() {
    return {
      users: [],
      hideUsers: [],
      respUsers: [],
      statistics: {
        doneCounter: 0,
        siteCounter: 0,
        todoCounter: 0,
        usersCounter: 0,
      },
      text: "",
      loading: false,
      overlay: false,
      overlayContent: '',
      userName: '',
      password: '',
      newPassword: '',
      maxRate: 0,
      minRate: 0,
      refreshCount: 0,
      uploadRecord: '',
      userSelected: {},
      oneDayUserActivity: {
        oneDayActivity: {
          sitesCounter: 0,
          averageOnPerSite: 0,
          noContactFrom: {
            counter: 0
          }
        },
        sitesList: []
      },
      dayAgo: 0,
      refreshText : '',
    }
  },

  methods: {
    loadAdmin: async function(){
      if (localStorage.hideUsers) {
        this.hideUsers = JSON.parse(localStorage.hideUsers);
      }
      let response;
      try {
        response = await this.axios.post(this.$store.state.baseUrl+"/admin", {}, {
          headers: {
            Authorization: this.$store.state.user.token,
          }
        });
        if (response.data.success) {
          this.statistics.doneCounter = response.data.data.doneCounter;
          this.statistics.siteCounter = response.data.data.siteCounter;
          this.statistics.todoCounter = response.data.data.todoCounter;
          this.statistics.usersCounter = response.data.data.usersCounter;
          this.respUsers = response.data.data.users;
          this.text = response.data.data.text;
          this.maxRate = parseInt(response.data.data.overTime);
          this.minRate = parseInt(response.data.data.rateLimit);
          this.refreshCount = parseInt(response.data.data.refreshCount);
          this.refreshText = response.data.data.refreshText;
        } else {
          this.$snotify.error(response.data.data.message);
        }
      } catch (e) {
      }

      let flag = true;
      for (let user of this.respUsers) {
        user.maxTime = false;
        user.minTime = false;
        flag = true;
        let sites = user.sitesList;
        for (let i=0; i < sites.length;i++) {
          if (i !== sites.length - 1 && (parseInt(sites[i].timestamp) - parseInt(sites[i + 1].timestamp)) > this.maxRate) {
            user.maxTime = true;
          }
          if (i !== sites.length - 1 && (parseInt(sites[i].timestamp) - parseInt(sites[i + 1].timestamp)) < this.minRate) {
            user.minTime = true;
          }
        }
        for (let hideUser of this.hideUsers) {
          if (user.id == hideUser) {
            user.hide = true;
            flag = false;
            break;
          }
        }
        if (flag) {
          this.users.push(user)
        }
      }
    },
    updateText: async function () {
      if (!this.loading) {
        this.loading = true;
        this.messageText = "";
        let response;
        try {
          response = await this.axios.post(this.$store.state.baseUrl+"/edit-setting", {
              settingName: "text",
              value: this.text
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {
            this.$snotify.success(response.data.data.message);
            setTimeout(() => {
              location.reload();
            }, 2000)
          } else {
            this.$snotify.error(response.data.data.message);
          }
        } catch (e) {
        }
        this.loading = false;
      }
    },
    updateRefreshText: async function () {
      if (!this.loading) {
        this.loading = true;
        this.messageText = "";
        let response;
        try {
          response = await this.axios.post(this.$store.state.baseUrl+"/edit-setting", {
              settingName: "refreshText",
              value: this.refreshText
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {
            this.$snotify.success(response.data.data.message);
            setTimeout(() => {
              location.reload();
            }, 2000)
          } else {
            this.$snotify.error(response.data.data.message);
          }
        } catch (e) {
        }
        this.loading = false;
      }
    },
    addUser: async function () {
      if (!this.userName || !this.password) {
        this.$snotify.error("Check inputs!");
        return false
      }
      if (!this.loading) {
        this.loading = true;
        this.messageAddUser = "";
        let response;
        try {
          response = await this.axios.post(this.$store.state.baseUrl+"/add-user", {
              userName: this.userName,
              password: this.password
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {
            this.$snotify.success(response.data.data.message);
            this.userName = "";
            this.password = "";
            setTimeout(() => {
              location.reload();
            }, 2000)
          } else {
            this.$snotify.error(response.data.data.message);
          }
        } catch (e) {
        }
        this.loading = false;

      }
    },
    upload: async function () {
      if (!this.loading) {
        this.loading = true;
        this.messageText = "";
        let response;
        let formData = new FormData();
        let file = document.querySelector('#upload');
        formData.append("file", file.files[0]);
        try {
          response = await this.axios.post(this.$store.state.baseUrl+'/import-database', formData, {
            headers: {
              Authorization: this.$store.state.user.token,
            }
          });
          if (response.data.success) {
            this.$snotify.success("file Uploaded.");
            this.uploadRecord = "new records:" + response.data.data.newRecords + "<br>duplicated: " + response.data.data.duplicatedRecords
            setTimeout(() => {
              location.reload();
            }, 3000)
          } else {
            this.$snotify.error(response.data.data.message);
          }
        } catch (e) {
        }

        this.loading = false;
      }
    },
    updateRateLimit: async function () {
      if (!this.loading) {
        this.loading = true;
        let response;
        try {

          response = await this.axios.post(this.$store.state.baseUrl+"/edit-setting", {
              settingName: "overTime",
              value: this.maxRate
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {
            response = await this.axios.post(this.$store.state.baseUrl+"/edit-setting", {
                settingName: "rateLimit",
                value: this.minRate
              },
              {
                headers: {
                  Authorization: this.$store.state.user.token,
                }
              });
            if (response.data.success) {
              this.$snotify.success(response.data.data.message);
              this.loading = false;
              setTimeout(() => {
                location.reload();
              }, 2000)
            } else {
              this.$snotify.error(response.data.data.message);
            }

          } else {
            this.$snotify.error(response.data.data.message);
          }


        } catch (e) {
        }
        this.loading = false;
      }
    },
    hideUser: function (userIndex, user) {
      this.users.splice(userIndex, 1)
      this.hideUsers.push(user.id);
      localStorage.hideUsers = JSON.stringify(this.hideUsers);
    },
    showAllUser: function () {
      this.hideUsers = [];
      localStorage.hideUsers = JSON.stringify(this.hideUsers);
      this.users = JSON.parse(JSON.stringify(this.respUsers));

    },
    changePass: async function (user) {
      if (!this.loading) {
        this.loading = true;
        let response;
        try {

          response = await this.axios.post(this.$store.state.baseUrl+"/update-user", {
              id: this.userSelected.id,
              field: "password",
              value: this.newPassword
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {
            this.$snotify.success(response.data.data.message);
            this.loading = false;
            this.newPassword = "";
            this.overlay = false;
          } else {
            this.$snotify.error(response.data.data.message);
          }

        } catch
          (e) {
        }
        this.loading = false;
      }
    },
    openOverlay: function (name, user) {
      this.userSelected = user;
      if (name === "userActivity") {
        this.getUserActivity();
      }
      this.overlay = true;
      this.overlayContent = name;

    },
    closeOverlay: function () {
      this.overlay = false
    },
    getUserActivity: async function () {
      this.loading = true;
      try {
        let response = await this.axios.post(this.$store.state.baseUrl+"/one-day-user-activity", {
          id: this.userSelected.id,
          dayAgo: this.dayAgo
        }, {
          headers: {
            Authorization: this.$store.state.user.token,
          }
        });
        this.loading = false;


        if (response.data.success) {
          this.oneDayUserActivity = response.data.data;
        } else {
          this.$snotify.error(response.data.data.message);
        }
      } catch (e) {

      }


    },
    updateRefreshCount: async function () {
      if (!this.loading) {
        this.loading = true;
        let response;
        try {

          response = await this.axios.post(this.$store.state.baseUrl+"/edit-setting", {
              settingName: "refreshCount",
              value: this.refreshCount
            },
            {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });
          if (response.data.success) {

              this.$snotify.success(response.data.data.message);
              this.loading = false;
              setTimeout(() => {
                location.reload();
              }, 2000)
            } else {
              this.$snotify.error(response.data.data.message);
            }
        } catch (e) {
        }
        this.loading = false;
      }
    },
  },

  async created() {

    setTimeout(()=>{
      this.loadAdmin();
    },500)

  },


}
</script>

<style lang="scss" scoped>
@import "@/assets/responsible";

.admin {
  background: #E1E3E7;
  min-height: 100vh;
  padding-bottom: 25px;
}

.rad-navigation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 0px 9px 4px rgba(0, 0, 0, 0.1), 0 -5px 2px 2px rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 100;
  text-align: center;
  height: 75px;
  padding-top: 15px;
  font-size: 25px;
  font-weight: bold;
}

main {
  padding-top: 125px;
}

.counter {
  margin-bottom: 25px;
}

.rad-info-box {
  margin-bottom: 16px;
  padding: 50px 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: white !important;
}

.rad-info-box span.mdi {
  display: block;
  margin-right: 15px;
  text-align: center;
  position: absolute;
  transform: translateY(-15px);
}

.item1 svg {
  fill: #1C7EBB;
}

.item2 svg {
  fill: #23AE89;
}

.item3 svg {
  fill: #E94B3B;
}

.item4 svg {
  fill: #FEC005;
}

.rad-info-box .value,
.rad-info-box .heading {
  display: block;
  position: relative;
  color: #515d6e;
  text-align: right;
  z-index: 10;
}

.rad-info-box .heading {
  font-size: 1.2em;
  font-weight: 300;
  text-transform: uppercase;
}

.rad-info-box .value {
  font-size: 2.1em;
  font-weight: 600;
  margin-top: 5px;
}

.panel {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 0 !important;
  position: relative;
  -webkit-transition: all .2s linear;
}

.panel-body {
  position: relative;
  -webkit-transition: all .2s linear;
  background: white;
  padding: 10px;
}

.panel-heading {
  background: #fafbfd !important;
  border-radius: 0;
  padding: 15px;
  border-bottom: 0 !important;

  span {
    float: right;
    cursor: pointer;
  }
}

.panel-heading .panel-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: #515d6e;
}

/**/
table {
  width: 100%;
  table-layout: fixed;
}

.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}

.tbl-content {
  height: 500px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

th {
  padding: 20px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: black;
  text-transform: uppercase;
}

td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  font-weight: 300;
  font-size: 12px;
  color: black;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

tbody td:first-of-type {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.users {
  margin-bottom: 25px;
}

.upload-input {
  color: transparent;
}

.upload-input::-webkit-file-upload-button {
  visibility: hidden;
}

#upload {
}

.upload-file {
  background: #00bfff;
  margin-left: 0;
}

a.upload-file {
  border-radius: 25px;
  padding: 10px 35px;
  color: white;
  margin-bottom: 25px;
}
textarea {
  width: 100%;
  margin-bottom: 10px;
}

.inputBox {
  max-width: 300px;
}

.pointer {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.overlay {
  background: rgba(34, 30, 30, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.overlay-content {
  max-width: 80%;
  max-height: 80%;
  position: fixed;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;

  .panel-heading {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
  }
}

.activity-panel {
  width: 80% !important;
  margin-right: 10px;
}

.dayAgo {
  width: 50px;
  border: 1px solid #4c4c4c;
  margin: 0;
}
.go{
  height: 30px;
  width: 100px;
}

@include respond("mobiles") {

}


</style>
