<template>
  <div class="auth">
    <div class="bg">
    </div>
    <div class="form d-flex justify-content-center align-items-center">
      <div class="blurred-box">
        <div class="block-center mb-2">
          <mdiLogin size="50"></mdiLogin>
        </div>
        <h1 class="text-center">Login</h1>
        <h3 class="text-center">Sign in here</h3>

        <div class="inputBox">
          <input v-model="userName" type="text" placeholder="Username"/>
          <span><mdi-account></mdi-account></span>
        </div>
        <div class="inputBox">
          <input v-model="password" type="password" placeholder="Password"/>
          <span><mdi-shield-key-outline></mdi-shield-key-outline></span>
        </div>
        <button @click="login">
          <template v-if="loading">
            <mdi-loading spin></mdi-loading>
          </template>
          <template v-else>
            Login
          </template>

        </button>
        <div class="text-center error mt-3">
          {{message}}
        </div>

      </div>
    </div>
  </div>
</template>


<script>
  import mdiLogin from "mdi-vue/Login.vue";
  import mdiAccount from "mdi-vue/Account.vue";
  import mdiShieldKeyOutline from "mdi-vue/ShieldKeyOutline.vue";
  import mdiLoading from "mdi-vue/Loading.vue";

  export default {
    name: "login",
    components: {
      mdiLogin,
      mdiAccount,
      mdiShieldKeyOutline,
      mdiLoading,
    },
    data() {
      return {
        userName: '',
        password: '',
        loading: false,
        message: ''
      }
    },
    methods: {
      login: async function () {
        if (!this.loading) {
          this.message = "";
          this.loading = true;
          let response;

          try {
            response = await this.axios.post(this.$store.state.baseUrl+"/login", {
              userName: this.userName,
              password: this.password
            });
          } catch (e) {
          }
          this.loading = false;

          if (response.data.success) {
            response.data.data.superUser = parseInt(response.data.data.superUser)
            this.$store.commit("loadUser", response.data.data);

            if (response.data.data.superUser) {
              this.$router.push({name: 'admin'})
            } else {
              this.$router.push({name: 'home'})
            }
          } else {
            this.message = response.data.data.message;
          }
        }

      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/responsible";

  /* body, html, .auth {
     overflow: hidden;
   }*/
  .auth {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .bg {
    width: 100%;
    height: 100vh;
    background: url("../assets/login.jpg") no-repeat;
    background-size: cover;
  }

  .form {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;

    :first-child {
      justify-content: center;
      align-items: center;
    }

  }


  .blurred-box {
    width: 350px;
    padding: 50px 10px;
  }


  h1 {
    margin: 0;
    padding: 0 0 20px;
    color: #262626;
    text-align: center;
  }

  button {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    height: 40px;
    font-size: 16px;
    background: #ed292a;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
  }


  @include respond("mobiles") {
    .blurred-box {
      width: 90%;
    }

  }


</style>
