<template>
  <div class="home">
    <div class="container">
      <div class="d-flex flex-column justify-content-center">
        <section class="header">
          <div class="block-center mb-2">
            <mdiSemanticWeb size="50"></mdiSemanticWeb>
          </div>
          <h1 class="text-center">Email system</h1>
          <hr>
        </section>
        <transition name="fade">
          <button v-if="!showMain" id="start" @click="start">Start</button>
        </transition>
        <transition name="fade">
          <section class="main" v-if="showMain" >

            <div class="progress">
              <div>
                0
                <mdi-crosshairs size="25"></mdi-crosshairs>
              </div>
              <div class="bar">
                <div class="bar-chart" :style="{width: Math.floor((todaySiteCount / refreshCount)*100) + '%'}">
                  <span v-if="todaySiteCount">
                    {{ todaySiteCount }} ({{ Math.floor((todaySiteCount / refreshCount) * 100) }}%)
                  </span>
                </div>
              </div>
              <div>
                <mdi-flag size="25"></mdi-flag>
                {{ refreshCount }}
              </div>
            </div>

            <a :href="'http://'+site" target="_blank"><h2 class="text-center">{{ site }}</h2></a>
            <textarea id="text" rows="6">{{ text }}</textarea>

            <div class="row justify-content-between">
              <div class="col-auto">
                <button id="finish" @click="finish">Finish</button>
              </div>

              <div class="col-auto">
                <button id="noForm" @click="noForm">
                  <template v-if="loadingN">
                    <mdi-loading spin></mdi-loading>
                  </template>
                  <template v-else>
                    No Contact Form
                  </template>

                </button>
                <div class="text-center error">
                  {{ messageN }}
                </div>
              </div>

              <div class="col-auto">
                <button id="next" @click="next">
                  <template v-if="loading">
                    <mdi-loading spin></mdi-loading>
                  </template>
                  <template v-else>
                    Next URL
                  </template>

                </button>
                <div class="text-center error">
                  {{ message }}
                </div>
              </div>
            </div>
          </section>
        </transition>
      </div>
    </div>

    <div class="overlay" v-show="showModal">
      <div class="box">
        <p v-html="refreshText"></p>
        <button @click="showModal = false; refresh=false">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import mdiSemanticWeb from "mdi-vue/SemanticWeb.vue";
import mdiFlag from "mdi-vue/Flag.vue";
import mdiCrosshairs from "mdi-vue/Crosshairs.vue";
import mdiAccount from "mdi-vue/Account.vue";
import mdiShieldKeyOutline from "mdi-vue/ShieldKeyOutline.vue";
import mdiLoading from "mdi-vue/Loading.vue";

export default {
  name: "login",
  components: {
    mdiSemanticWeb,
    mdiAccount,
    mdiShieldKeyOutline,
    mdiLoading,
    mdiFlag,
    mdiCrosshairs,
  },
  data() {
    return {
      site: '',
      name: '',
      text: "",
      loading: false,
      loadingN: false,
      message: '',
      messageN: '',
      showMain: false,
      siteObj: {},
      refresh: false,
      showModal: false,
      refreshText: "",
      refreshCount: 0,
      todaySiteCount: 0,
    }
  },
  methods: {
    start: function () {
      this.showMain = true;
      setTimeout(() => {
        this.next();
      }, 200)
    },
    next: async function () {
      if (!this.refresh) {
        if (!this.loading) {
          this.loading = true;
          let response;

          try {
            response = await this.axios.post(this.$store.state.baseUrl + "/next-site", {}, {
              headers: {
                Authorization: this.$store.state.user.token,
              }
            });

            if (response.data.success) {
              this.site = response.data.data.url;
              this.name = response.data.data.name;
              this.text = response.data.data.text;
              this.siteObj = response.data.data;
              this.refresh = response.data.data.refresh;
              this.refreshText = response.data.data.refreshText;
              this.refreshCount = response.data.data.refreshCount;
              this.todaySiteCount = response.data.data.todaySiteCount;


              setTimeout(() => {
                let textEl = document.getElementById("text");
                textEl.select();
                console.log("selected")
                setTimeout(() => {
                  console.log("copy")
                  let successful = document.execCommand('copy');
                  successful = document.execCommand('copy');
                  console.log(successful);
                  if (successful) {
                    this.$snotify.success('Text copied to clipboard');
                    window.getSelection().removeAllRanges();
                    textEl.blur();
                    setTimeout(() => {
                      window.open('http://' + this.site, '_blank');
                    }, 700)
                  } else {
                    this.$snotify.error('Text not copied.');
                  }
                }, 10)
              }, 500)

            } else {
              this.message = response.data.data.message;
            }
          } catch (e) {

          }
          this.loading = false;
        }
      } else {
        this.showModal = true;
      }

    },
    noForm: async function () {
      if (!this.loadingN) {
        this.loadingN = true;
        let response;
        try {
          response = await this.axios.post(this.$store.state.baseUrl + "/no-contact-from", {
            id: this.siteObj.id,
            hasContactForm: false
          }, {
            headers: {
              Authorization: this.$store.state.user.token,
            }
          });

          if (response.data.success) {
            this.$snotify.success('Thanks');
          } else {
            this.messageN = response.data.data.message;
          }

        } catch (e) {

        }
        this.loadingN = false;

      }

    },
    finish: function () {
      this.showMain = false;
    }
  },
  created() {
    document.queryCommandSupported('copy');
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/responsible";

/* body, html, .auth {
   overflow: hidden;
 }*/
.home {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #e1e3e7;
  padding: 50px 10px;
}

h1 {
  margin: 0;
  padding: 0 0 20px;
  color: #262626;
  text-align: center;
}

h2 {
  margin: 50px 0;
  color: #262626;
  text-align: center;
  font-size: 40px;
}

textarea {
  width: 100%;
  font-size: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 5px 10px;
}


#next, #start, {
  background: #00b050;
}

#noForm {
  background: #E94B3B;
}

#start {
  margin-top: 25px;
}

#finish {
  background: #4472C4;
}

.container {
  min-height: 100%;
}

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;

  .bar {
    flex-grow: 1;
    margin: 0 10px;

  }

  .bar-chart {
    height: 25px;
    border-radius: 15px;
    background: #1C7EBB;
    text-align: center;
    color: white;
    max-width: 100%;
  }
}

.overlay {
  background: rgba(0, 0, 0, .8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    padding: 25px 50px;
    background: white;
    border-radius: 25px;

    p {
      font-size: 32px;
    }

    button {
      background: #4472C4;
    }
  }
}

@include respond("mobiles") {
}


</style>
