<template>
  <div id="app">
    <router-view/>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
export default {
  async created() {
    let resp = await this.axios.get('./config.json');
    /*let resp = await this.axios.get('./config-dev.json');*/
    this.$store.state.baseUrl = resp.data.appUrl;

    let user = localStorage.user;
    if (!user) {
      this.$router.push({name: 'login'})
    } else {
      user = JSON.parse(user);
      this.$store.commit("loadUser", user);

      if (this.$router.currentRoute.name !=="login"){
        if (this.$store.state.user.superUser) {
          this.$router.push({name: 'admin'})
        }
        else{
          this.$router.push({name: 'home'})
        }
      }

    }
  },
}
</script>

<style lang="scss">
@import './assets/grid.css';
@import "~vue-snotify/styles/dark";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

body, html {
  padding: 0;
  margin: 0;
  min-height: 100%;
  font-family: 'Noto Sans', sans-serif;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.blurred-box {
  position: relative;
  padding: 25px 10px;
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);

  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(202, 198, 198, 0.2);
    z-index: -1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 5px;
  }
}

.text-center {
  text-align: center;
}

.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

input {
  width: 100%;
  margin-bottom: 20px;
}

input[type="text"],
input[type="password"] {
  border: none;
  border-bottom: 2px solid #262626;
  outline: none;
  height: 40px;
  color: #262626;
  background: transparent;
  font-size: 16px;
  padding-left: 40px;
  box-sizing: border-box;
}

::placeholder {
  color: rgba(0, 0, 0, .5);
}

.inputBox {
  position: relative;

  span {
    position: absolute;
    top: 3px;
    left: 0;
    color: #262626;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.error {
  color: #ed292a;
}

.success {
  color: #00b050;
}

.warn {
  color: #FEC005;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

::-moz-selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}

button {
  display: block;
  border: none;
  outline: none;
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  color: #fff;
  width: 150px;
  margin: auto;
}

.debug1 {
  background: red;
}

.debug2 {
  background: blue;
}

.debug3 {
  background: green;
}
</style>
