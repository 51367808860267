import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: "",
    user:{
      userName: "",
      token:"",
      superUser: 0,
      id: "",
    }
  },
  mutations: {
    loadUser: (state, payload) => {
      state.user = payload;
      localStorage.user = JSON.stringify(state.user);
    },
  },
  actions: {
  },
  modules: {
  }
})
